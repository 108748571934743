// Get video player modules on the page
const videoPlayerModules = [
  ...document.querySelectorAll(".video-player"),
  ...document.querySelectorAll(".detailed-video-player")
]

videoPlayerModules.forEach((videoPlayerModule) => {
  // Get all video lists in the module
  const videoPlayerContainers = [
    ...videoPlayerModule.querySelectorAll(".video-list__grid"),
    ...videoPlayerModule.querySelectorAll(".video-list__flex")
  ]

  // For each individual video on the list
  videoPlayerContainers.forEach((videoPlayerContainer, idx) => {
    // Get the video associated with it
    let videoContainer = videoPlayerModule.querySelector(
      `#player-${videoPlayerModule.id}-container-${idx}`
    )

    videoPlayerContainer.addEventListener("click", (event) => {
      // Grab the module id from the container
      let moduleId = videoPlayerContainer.id.split("-")[1]

      // Hide all videos under that module id
      hideVideoPlayers(moduleId)

      // Display everything associated with the video
      const videoPlayingIcons = [
        videoPlayerContainer.querySelector(".video-list__icon"),
        videoPlayerContainer.querySelector(".video-list__icon--small")
      ]
      const videoPlayingIndicator = videoPlayerContainer.querySelector(
        ".video-list__playing-indicator"
      )
      videoPlayingIcons.forEach((videoPlayingIcon) => {
        videoPlayingIcon?.classList.remove("video-list__icon--hidden")
      })
      videoPlayingIndicator?.classList.remove(
        "video-list__playing-indicator--hidden"
      )
      videoContainer.classList.remove("hidden")
      showDetailsForVideo(moduleId, idx)
    })
  })
})

function hideVideoPlayers(moduleId) {
  let videoPlayerContainers = document.querySelectorAll(
    `[id^=player-${moduleId}-list]`
  )

  videoPlayerContainers.forEach((videoPlayerContainer, idx) => {
    hideVideoPlayerDetails(moduleId, idx)
    let videoContainer = document.querySelector(
      `#player-${moduleId}-container-${idx}`
    )
    const videoPlayingIcons = [
      videoPlayerContainer.querySelector(".video-list__icon"),
      videoPlayerContainer.querySelector(".video-list__icon--small")
    ]
    let videoPlayingIndicator = videoPlayerContainer.querySelector(
      ".video-list__playing-indicator"
    )

    videoPlayingIcons.forEach((videoPlayingIcon) => {
      videoPlayingIcon?.classList.add("video-list__icon--hidden")
    })
    videoPlayingIndicator?.classList.add(
      "video-list__playing-indicator--hidden"
    )
    videoContainer.classList.add("hidden")
  })
}

function hideVideoPlayerDetails(moduleId, idx) {
  let videoPlayerDetails = document.getElementById(
    `player-${moduleId}-video-${idx}-details`
  )
  videoPlayerDetails?.classList?.add("video-details--hidden")
}

function showFirstVideo() {
  const videoPlayerModules = [
    ...document.querySelectorAll(".video-player"),
    ...document.querySelectorAll(".detailed-video-player")
  ]

  videoPlayerModules.forEach((videoPlayerModule) => {
    hideVideoPlayers(videoPlayerModule.id)
    showFirstVideoDetails(videoPlayerModule.id)

    let videoPlayerContainers = [
      ...videoPlayerModule.querySelectorAll(".video-list__grid"),
      ...videoPlayerModule.querySelectorAll(".video-list__flex")
    ]

    const firstVideo = document.getElementById(
      `player-${videoPlayerModule.id}-container-0`
    )

    const videoPlayingIcons = [
      videoPlayerContainers[0].querySelector(".video-list__icon"),
      videoPlayerContainers[0].querySelector(".video-list__icon--small")
    ]
    const videoPlayingIndicator = videoPlayerContainers[0].querySelector(
      ".video-list__playing-indicator"
    )

    firstVideo?.classList?.remove("hidden")
    videoPlayingIcons.forEach((videoPlayingIcon) => {
      videoPlayingIcon?.classList.remove("video-list__icon--hidden")
    })
    videoPlayingIndicator?.classList.remove(
      "video-list__playing-indicator--hidden"
    )
  })
}

function showFirstVideoDetails(moduleId) {
  showDetailsForVideo(moduleId, 0)
}

function showDetailsForVideo(moduleId, id) {
  let videoPlayerDetails = document.getElementById(
    `player-${moduleId}-video-${id}-details`
  )
  videoPlayerDetails?.classList?.remove("video-details--hidden")
}

if (videoPlayerModules.length) {
  showFirstVideo()
}
