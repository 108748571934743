import { distance } from "./haversine"
import $ from "jquery"

// The Zoom ratio is calculated non-trivially using the following formula:
//metersPerPx = 156543.03392 * Math.cos(latLng.lat() * Math.PI / 180) / Math.pow(2, zoom)

const CITY_ZOOM = 12
const COUNTRY_ZOOM = 6
const DEFAULT_ZOOM = 12
const INITIAL_ZOOM = 4

export default class StoreLocator {
  constructor() {
    this.closeDistance = 30
    this.geocoder = null
    this.map = null
    this.markerClusterConfig = {}
    this.markers = []
    this.markerSvgSource = null
    this.maxStoresInList = 100
    this.pointInfoRenderer = (_point) => {
      return ""
    }
    this.stores = []
  }

  onMapReady = () => {
    if (!(this.stores && this.stores.length)) {
      return
    }

    let mapOptions = {
      mapTypeId: "roadmap",
      disableDefaultUI: true,
      center: { lat: 58, lng: 7.5 },
      zoom: INITIAL_ZOOM,
      styles: [
        {
          featureType: "poi.business",
          elementType: "labels",
          stylers: [{ visibility: "off" }]
        }
      ]
    }

    this.map = new google.maps.Map(
      document.getElementById("map_canvas"),
      mapOptions
    )

    google.maps.event.addListener(this.map, "idle", () => {
      this.handleIdle()
    })

    this.geocoder = new google.maps.Geocoder()
    this.drawMarkers(this.stores)
    this.setAutocomplete()
    this.scrollToMark()
  }

  clearMarkers = () => {
    for (var i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null)
    }
    this.markers = []
  }

  drawMarkers = (points) => {
    if (!(points && points.length)) {
      points = this.stores
    }

    const infoWindowContent = points.map(this.pointInfoRenderer)

    for (const [index, point] of points.entries()) {
      let position = new google.maps.LatLng(point.lat, point.lon)

      let defaultMarkerSvg = window.StoreFinder.markerSvgSource

      let marker = new google.maps.Marker({
        icon: {
          size: new google.maps.Size(30, 30),
          scaledSize: new google.maps.Size(30, 30),
          url: this.markerSvgSource || defaultMarkerSvg
        },
        map: this.map,
        position: position,
        storeId: point.id,
        title: point.name
      })

      this.markers.push(marker)

      // Allow each marker to have an info window
      let infoWindow = new google.maps.InfoWindow()

      google.maps.event.addListener(
        marker,
        "click",
        ((marker, point) => {
          return () => {
            infoWindow.setContent(infoWindowContent[index])
            infoWindow.open(window.StoreFinder.map, marker)
          }
        })(marker, point)
      )
    }

    this.markerCluster = new MarkerClusterer(
      this.map,
      this.markers,
      this.markerClusterConfig
    )

    if (points.length === 1) {
      this.map.setZoom(CITY_ZOOM)
    }
  }

  handleIdle = () => {
    let bounds = this.map.getBounds()
    let visibleIds = []

    for (var i = 0; i < this.markers.length; i++) {
      let marker = this.markers[i]

      if (bounds.contains(marker.getPosition()) === true) {
        visibleIds.push(marker.storeId)
      }
    }

    let $list = $(".js-stores-container .list")
    $list.children().hide()

    if (visibleIds.length < this.maxStoresInList) {
      visibleIds.forEach((id) => {
        $list.children(`[data-store-id=${id}]`).show()
      })
    }
  }

  setLocation = (position) => {
    let point = {
      lat: position.coords.latitude,
      lon: position.coords.longitude
    }

    this.onLocationSelected(point)
    this.map.setCenter(new google.maps.LatLng(point.lat, point.lon))
    this.map.setZoom(DEFAULT_ZOOM)
  }

  setAutocomplete = () => {
    const card = document.getElementById("pac-card")
    const input = document.getElementById("pac-input")

    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(card)

    const autocomplete = new google.maps.places.Autocomplete(input)

    // Bind the map's bounds (viewport) property to the autocomplete object,
    // so that the autocomplete requests use the current map bounds for the
    // bounds option in the request.
    autocomplete.bindTo("bounds", this.map)

    // Set the data fields to return when the user selects a place.
    autocomplete.setFields([
      "address_components",
      "types",
      "geometry",
      "icon",
      "name"
    ])

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace()

      if (!place.geometry) {
        window.alert("No details available for input: '" + place.name + "'")
        return
      }
    })

    this.selectFirstAutocompleteSuggestionOnEnter(input)
  }

  setMaxStoresInList = (maxStoresInList) => {
    this.maxStoresInList = maxStoresInList
  }

  selectClosePoints = (center) => {
    return this.stores.filter((el) => {
      return distance(center, el) <= this.closeDistance
    })
  }

  setLocationToUsersHomeStore = (position) => {
    let latLng = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    }

    this.geocoder
      .geocode({
        location: latLng
      })
      .then((geocoderResults) => {
        return this.retrieveUserCountry(geocoderResults)
      })
      .then((userCountry) => {
        return this.filterStoresByCountry(userCountry)
      })
      .then((storesInCountry) => {
        if (storesInCountry.length > 0) {
          return this.retrieveClosestStore(position, storesInCountry)
        } else {
          window.alert("No stores found in your country")
          return this.retrieveClosestStore(position, this.stores)
        }
      })
      .then((closestStore) => {
        let point = {
          coords: {
            latitude: closestStore["lat"],
            longitude: closestStore["lon"]
          }
        }

        this.onLocationSelected({
          lat: closestStore["lat"],
          lon: closestStore["lon"]
        })

        this.setLocation(point)
      })
      .catch((err) => console.error(err))
  }

  retrieveUserCountry = (googleGeocodeResults) => {
    //When using reverse geocoding API we must filter results manually. Reference: https://issuetracker.google.com/issues/35826518?pli=1
    if (!googleGeocodeResults) return "None"

    for (const result of googleGeocodeResults.results) {
      if (result.types.includes("country")) {
        return result["address_components"].find((el) =>
          el.types.includes("country")
        )["long_name"]
      }
    }
  }

  filterStoresByCountry = (userCountry) => {
    return this.stores.filter((store) => {
      return (
        store["country"].toLowerCase().trim() ===
        userCountry.toLowerCase().trim()
      )
    })
  }

  retrieveClosestStore = (center, storeList) => {
    let position = {
      lat: center.coords.latitude,
      lon: center.coords.longitude
    }

    if (!storeList) return

    return storeList
      .map((el) => {
        return {
          distance: distance(position, el),
          store: el
        }
      })
      .sort((el1, el2) => {
        return el1.distance - el2.distance
      })[0].store
  }

  onLocationSelected = (point) => {
    let points = this.selectClosePoints(point)

    this.clearMarkers()
    this.drawMarkers(points)
  }

  retrievePlace = () => {
    const input = document.getElementById("pac-input")
    const service = new google.maps.places.PlacesService(this.map)

    if (input.value === "") {
      return
    }

    const request = {
      query: input.value,
      fields: ["name", "geometry", "types"]
    }

    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const place = results[0]

        if (!place.geometry) {
          window.alert("No details available for input: '" + place.name + "'")
        } else {
          let position = {
            coords: {
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng()
            }
          }
          this.setLocationToUsersHomeStore(position)
        }
      } else {
        window.alert("We could not find that location or ZIP code.")
      }
    })
  }

  scrollToMark = () => {
    document.querySelectorAll(".js-store-info").forEach((elem) => {
      var scrollPoint = this.markers.find(function (marker) {
        return (
          marker.title.toLowerCase() ===
          elem
            .querySelector(".store-name")
            .getAttribute("data-store-name")
            .toLowerCase()
        )
      })

      google.maps.event.addDomListener(elem, "click", () => {
        // Handle store list selection styles
        $(".js-store-info").removeClass("selected")
        elem.classList.add("selected")

        window.innerWidth < 960 && window.scrollTo({ top: 0, left: 100 })

        google.maps.event.trigger(scrollPoint, "click", {})

        this.map.setCenter({
          lat: scrollPoint.getPosition().lat(),
          lng: scrollPoint.getPosition().lng()
        })

        this.map.setZoom(DEFAULT_ZOOM)
      })
    })
  }

  selectFirstAutocompleteSuggestionOnEnter = (input) => {
    // Store the original event binding function
    const _addEventListener = input.addEventListener
      ? input.addEventListener
      : input.attachEvent

    // Simulate a 'down arrow' keypress on hitting 'return' when no PAC is
    // available. Select the first suggestion, then trigger the original
    // listener.
    const addEventListenerWrapper = (type, listener) => {
      const enterKeyCode = 13
      const downArrowKeyCode = 40

      if (type === "keydown") {
        const originalListener = listener

        listener = (event) => {
          const suggestionSelected = $(".pac-item-selected").length > 0
          if (event.which === enterKeyCode && !suggestionSelected) {
            const simulatedDownArrow = $.Event("keydown", {
              keyCode: downArrowKeyCode,
              which: downArrowKeyCode
            })

            originalListener.apply(input, [simulatedDownArrow])
          }

          originalListener.apply(input, [event])
        }
      }

      // Add the modified listener
      _addEventListener.apply(input, [type, listener])
    }

    if (input.addEventListener) {
      input.addEventListener = addEventListenerWrapper
    } else if (input.attachEvent) {
      input.attachEvent = addEventListenerWrapper
    }
  }

  submitFormContents = () => {
    this.retrievePlace()

    return false
  }

  zoomLevel = (typesList) => {
    if (typesList.includes("locality")) {
      return CITY_ZOOM
    } else if (typesList.includes("country")) {
      return COUNTRY_ZOOM
    } else {
      return DEFAULT_ZOOM
    }
  }

  zoomToPlace = (geometry, placeTypes) => {
    if (geometry.viewport) {
      this.map.fitBounds(geometry.viewport)
    } else {
      this.map.setCenter(geometry.location)
    }

    this.map.setZoom(this.zoomLevel(placeTypes))
  }
}
