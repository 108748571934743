// mobile-header--sticky and site-header--sticky classes are added
// to the body element and will be used to style the pdp-info section height accordigly.
// So the add to cart button is always visible when the user scrolls
// down the page or resizes the window

// Also the top-bar height is calculated and set as a css variable
// so the top-bar can have different heights depending on its content.

import getBreakpoint from "./getBreakpoint"
import debounce from "lodash/debounce"

const initializeTopBarHeightAndStickyHeaders = (): void => {
  const topBar = document.getElementById("top-bar")
  const header = document.getElementById("site-header")
  const mobileHeader = document.getElementById("mobile-header")
  const desktopBreakpoint = getBreakpoint("--breakpoint-desktop-large")

  if (!header) {
    return
  }

  // Function to update the height of the top bar
  const updateTopBarHeight = () => {
    if (!topBar) {
      document.documentElement.style.setProperty("--top-bar-height", "0px")
      return
    }

    const topBarHeight = topBar.offsetHeight
    const isVisible = !(
      document.body.classList.contains("mobile-header--sticky") ||
      document.body.classList.contains("site-header--sticky")
    )

    document.documentElement.style.setProperty(
      "--top-bar-height",
      isVisible ? `${topBarHeight}px` : "0px"
    )
  }

  const setTopBarHeight = () => {
    window.requestAnimationFrame(updateTopBarHeight)
  }

  // Initialize top bar height on page load
  setTopBarHeight()

  // Add event listeners for resize, scroll, and page load
  ;["DOMContentLoaded", "resize", "scroll"].forEach((event) => {
    window.addEventListener(event, setTopBarHeight)
  })

  // Intersection Observer for sticky headers based on the top bar visibility
  const observerOptions = {
    root: null,
    threshold: [0], // We want to know as soon as the top bar starts/stops intersecting
    rootMargin: `-5px 0px 0px 0px` // this negative top margin prevents flickering
  }

  const handleStickyHeader = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0]
    document.body.classList.remove(
      "mobile-header--sticky",
      "site-header--sticky"
    )

    const isDesktopWidth = window.innerWidth > desktopBreakpoint
    const isTopBarVisible = entry.isIntersecting

    if (isDesktopWidth) {
      document.body.classList.toggle("site-header--sticky", !isTopBarVisible)
    } else if (mobileHeader && window.innerWidth < desktopBreakpoint) {
      document.body.classList.toggle("mobile-header--sticky", !isTopBarVisible)
    }
  }

  const observer = new IntersectionObserver(handleStickyHeader, observerOptions)

  if (topBar) {
    observer.observe(topBar) // Observe the top bar element
  }

  // Debounced resize handler
  const handleResize = debounce(() => {
    document.body.classList.remove(
      "mobile-header--sticky",
      "site-header--sticky"
    )

    const simulateIntersectionEntry = (
      element: HTMLElement
    ): IntersectionObserverEntry => {
      return {
        time: 0,
        target: element,
        rootBounds: null,
        boundingClientRect: element.getBoundingClientRect(),
        intersectionRect: element.getBoundingClientRect(),
        intersectionRatio: 1,
        isIntersecting: element.getBoundingClientRect().top >= 0
      }
    }

    const topBarEntry = topBar ? simulateIntersectionEntry(topBar) : null

    // First, validate for the intersection (sticky logic)
    if (topBarEntry) {
      handleStickyHeader([topBarEntry])
    }

    // Then, update the top bar height
    setTopBarHeight()
  }, 150)

  let resizeTimeout: number
  window.addEventListener("resize", () => {
    clearTimeout(resizeTimeout)
    document.body.classList.remove(
      "mobile-header--sticky",
      "site-header--sticky"
    )

    // After resize settles, re-apply the sticky logic and top bar height
    resizeTimeout = window.setTimeout(handleResize, 150)
  })
}

initializeTopBarHeightAndStickyHeaders()
