window.addEventListener("DOMContentLoaded", () => {
  const menu = document.getElementById("mobile-menu")

  if (!menu) {
    return
  }

  menu
    .querySelectorAll(".navigation-mobile-primary__section-toggle")
    .forEach((toggle) => {
      toggle.addEventListener("click", (event) => {
        event.preventDefault()
        toggle.classList.toggle(
          "navigation-mobile-primary__section-toggle--open"
        )

        const drawer = Array.from(
          menu.querySelectorAll(".navigation-mobile-drawer")
        ).find((drawer) => drawer.dataset.drawerId == toggle.dataset.drawerId)

        drawer.classList.toggle("navigation-mobile-drawer--open")
      })
    })

  const hamburger = document.getElementById("mobile-header-hamburger")

  if (!hamburger) {
    return
  }

  hamburger.addEventListener("click", (event) => {
    menu.classList.toggle("mobile-menu--open")
  })

  const closeButton = document.getElementById("mobile-menu-close-button")

  if (!closeButton) {
    return
  }

  closeButton.addEventListener("click", (event) => {
    menu.classList.toggle("mobile-menu--open")
  })
})
