import CircleType from "circletype"

const heroBanners = [
  ...document.querySelectorAll(".hero-banner-with-curved-text")
]

heroBanners.map((banner) => {
  new CircleType(
    banner.querySelector(".hero-banner-with-curved-text__super-title")
  ).radius(250)
})
