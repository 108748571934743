// This is based on https://developers.google.com/maps/solutions/store-locator/clothing-store-locator
// which uses the Haversine formula https://en.wikipedia.org/wiki/Haversine_formula
export function distance(centre, point, units = "miles") {
  let units_constant = units !== "miles" ? 6371 : 3959

  return (
    units_constant *
    Math.acos(
      Math.cos(radians(centre.lat)) *
        Math.cos(radians(point.lat)) *
        Math.cos(radians(centre.lon) - radians(point.lon)) +
        Math.sin(radians(centre.lat)) * Math.sin(radians(point.lat))
    )
  )
}

export function radians(degrees) {
  return (degrees * Math.PI) / 180
}

export function degrees(radians) {
  return (radians * 180) / Math.PI
}
