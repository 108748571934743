import StoreLocator from "./storeLocator"

window.StoreFinder = new StoreLocator()

StoreFinder = window.StoreFinder

document.addEventListener("DOMContentLoaded", () => {
  const useMyLocationButton = document.querySelector(".js-use-my-location")

  if (!useMyLocationButton) {
    return
  }

  useMyLocationButton.addEventListener("click", (element) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        StoreFinder.setLocation(position)
        StoreFinder.setLocationToUsersHomeStore(position)
      })
    }
  })
})

document.addEventListener("DOMContentLoaded", () => {
  const showOptionsButton = document.querySelector(".js-show-options")
  const hideOptionsButton = document.querySelector(".js-hide-options")
  const sidebar = document.querySelector(".store-finder-sidebar")
  const map = document.querySelector(".store-finder-main-area")

  if (!showOptionsButton || !hideOptionsButton || !sidebar || !map) {
    return
  } else {
    showOptionsButton.addEventListener("click", (element) => {
      showOptionsButton.classList.add("hidden")
      sidebar.classList.remove("hidden")
      hideOptionsButton.classList.remove("hidden")
      map.classList.remove("mega-mobile-map")
    })

    hideOptionsButton.addEventListener("click", (element) => {
      sidebar.classList.add("hidden")
      hideOptionsButton.classList.add("hidden")
      showOptionsButton.classList.remove("hidden")
      map.classList.add("mega-mobile-map")
    })
  }
})

// Only call enableStoreFinder if it has been defined by finder.html.erb
typeof window.enableStoreFinder === typeof Function
  ? window.enableStoreFinder()
  : ""
