const closeOpenModal = () => {
  const openModal = document.querySelector(
    ".modals__overlay:not(.modals__overlay--closed)"
  )

  if (!openModal) {
    return
  }

  openModal.classList.add("modals__overlay--closed")
}

document.addEventListener("showModal", (e) => {
  closeOpenModal()

  const modal = document.getElementById(`modal_${(<CustomEvent>e).detail}`)
  if (modal) modal.classList.remove("modals__overlay--closed")
})

document.addEventListener("closeModals", (e) => {
  closeOpenModal()
})

document.addEventListener("click", (e) => {
  const target = e.target && <Element>e.target

  if (!target) return

  if (
    target.matches(".modals__overlay") ||
    target.matches(".modals__close-button")
  ) {
    closeOpenModal()
  }
})
