import debounce from "lodash/debounce"

const cartForm = document.getElementById("update-cart")

if (cartForm) {
  const state = { freeForm: false }

  const deleteButtons = cartForm.querySelectorAll(
    "div.cart-secondary-actions__empty"
  )

  deleteButtons.forEach((deleteButton) => {
    deleteButton.addEventListener("click", () => {
      const lineItem = deleteButton.closest(".cart-item__container")

      if (!lineItem) return

      const input = lineItem.querySelector(".cart-item__quantity input")

      if (!input) return
      ;(<HTMLInputElement>input).value = "0"
    })
  })

  const quantityPickerInput = cartForm.querySelectorAll(
    ".quantity-picker__amount--input"
  )

  quantityPickerInput.forEach((input) => {
    input.addEventListener("click", (event) => {
      event.stopPropagation()
      state.freeForm = true
    })

    input.addEventListener("keydown", (event) => {
      const keyboardEvent = event as KeyboardEvent
      if (keyboardEvent.key === "Enter") {
        event.preventDefault()
        ;(<HTMLFormElement>cartForm).submit()
        state.freeForm = false
      }
    })

    const cartPageClass = document.querySelector(".cart-page")

    cartPageClass?.addEventListener("click", (event) => {
      if (input.contains(event.target as Node)) return
      if (state.freeForm) {
        ;(<HTMLFormElement>cartForm).submit()
        state.freeForm = false
      }
    })
  })

  const quantityPickerButtons = cartForm.querySelectorAll(
    ".quantity-picker__button"
  )

  quantityPickerButtons.forEach((button) => {
    button.addEventListener(
      "click",
      debounce(() => {
        ;(<HTMLFormElement>cartForm).submit()
      }, 300)
    )
  })
}
