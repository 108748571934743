const footer = document.getElementById("site-footer")

if (footer) {
  footer.addEventListener("click", (e) => {
    let column
    let buttonEl

    if (e.target.matches(".footer-column__heading button")) {
      column = e.target.dataset.footerColumn
      buttonEl = e.target
    } else if (e.target.matches(".footer-column__icon")) {
      column = e.target.parentElement.dataset.footerColumn
      buttonEl = e.target.parentElement
    }

    if (column && buttonEl) {
      footer
        .querySelector(`.footer-column__items[data-footer-column="${column}"]`)
        .classList.toggle("footer-column__items--open")
      toggleIcons(buttonEl)
    }
  })

  function toggleIcons(buttonEl) {
    const plus = buttonEl.querySelector(".footer-column__plus")
    const minus = buttonEl.querySelector(".footer-column__minus")
    plus.classList.toggle("footer-column__plus--hidden")
    minus.classList.toggle("footer-column__minus--hidden")
  }
}
