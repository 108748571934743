import CircleType from "circletype"

window.addEventListener("load", () => {
  const textRoundels = [...document.querySelectorAll(".text-roundel--normal")]
  const smallTextRoundels = [
    ...document.querySelectorAll(".text-roundel--small")
  ]

  const textRoundelRotate = document.querySelector(
    ".text-roundel__container--rotate"
  )

  textRoundels.forEach((roundel) => {
    if (textRoundelRotate) {
      new CircleType(roundel).radius(88)
    } else {
      new CircleType(roundel).radius(70)
    }
  })

  smallTextRoundels.forEach((roundel) => {
    new CircleType(roundel).radius(58)
  })
})
