import CircleType from "circletype"

window.addEventListener("load", () => {
  const navigationRoundels = [
    ...document.querySelectorAll(".navigation-roundel-container")
  ]

  navigationRoundels.map((roundel) => {
    let text = roundel.querySelector(".navigation-roundel__text")
    new CircleType(text).radius(80)
    let letters = roundel.querySelectorAll(".navigation-roundel__text span")

    // This padding covers up any additional circle in between letters.
    //
    // This has to be added here as there is a competition between our styling
    // and the styling that CircleType adds. If the style has been added too
    // early, then CircleType will do things differently than it is expected to.
    letters.forEach((letter) => {
      letter.classList.add("navigation-roundel__text--letter-padding")
    })

    // If we rotate before CircleType does its magic, we lose some of the styles.
    roundel
      .querySelector(".navigation-roundel__text-container")
      .classList.add("navigation-roundel__text-container--initial-rotation")
  })
})
