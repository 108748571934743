const bindCountrySelectorToggleEvents = () => {
  const countrySelectorToggle = document.getElementById(
    "country-selector-toggle"
  )
  const countrySelectorContainer = document.getElementById(
    "country-selector-container"
  )

  countrySelectorToggle.addEventListener("click", (_event) => {
    countrySelectorContainer.classList.toggle(
      "navigation-country-selector__container--open"
    )
  })

  // Close the drop down if you click outside it.
  document.addEventListener("click", (e) => {
    countrySelectorContainer.classList.remove(
      "navigation-country-selector__container--open"
    )
  })
}

const bindCountrySelectorFormEvents = (formId) => {
  const countrySelectorForm = document.getElementById(formId)
  const countrySelector = countrySelectorForm.querySelector("#new_country_iso")

  countrySelector.addEventListener("change", (_event) =>
    countrySelectorForm.submit()
  )
}

const bindLocaleSelectorFormEvents = (formId) => {
  const localeSelectorForm = document.getElementById(formId)

  // Some countries don't have a locale selector because they only support a
  // single locale.
  if (!localeSelectorForm) {
    return
  }

  const localeSelector = localeSelectorForm.querySelector("#new_locale")

  localeSelector.addEventListener("change", (_event) =>
    localeSelectorForm.submit()
  )
}

document.addEventListener("DOMContentLoaded", () => {
  const component = document.getElementById("navigation-country-selector")

  if (!component) {
    return
  }

  // This prevents click events from inside the component from escaping, so
  // that they can't trigger the event handler on the document that closes the
  // dropdown.
  component.addEventListener("click", (event) => event.stopPropagation())

  bindCountrySelectorToggleEvents()
  bindCountrySelectorFormEvents("country-selector-form")
  bindLocaleSelectorFormEvents("locale-selector-form")
})

document.addEventListener("DOMContentLoaded", () => {
  const component = document.getElementById("mobile-country-selector")

  if (!component) {
    return
  }

  bindCountrySelectorFormEvents("mobile-country-selector-form")
  bindLocaleSelectorFormEvents("mobile-locale-selector-form")
})
