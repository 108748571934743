import algoliaSetup from "./algoliaSetup"

// We wait until DOMContentLoaded so we have consistent access to CookieBot,
// who manages the user consent to being tracked.
document.addEventListener("DOMContentLoaded", () => {
  /* Set up the desktop search.
   */
  const desktopAutocomplete = algoliaSetup({
    id: "desktop", // This can be used for debugging.
    containerId: "site_search",
    buttonSelector: "#site_search_button",
    searchBoxSelector: "#site_search",
    closeOnScroll: true
  })

  /* Set up the mobile search.
   */
  const mobileAutocomplete = algoliaSetup({
    id: "mobile", // This can be used for debugging.
    containerId: "mobile-search-container",
    buttonSelector: ".mobile-search-button",
    searchBoxSelector: "#mobile-site-search",
    closeOnScroll: false
  })

  /* Algolia does not support multiple instances of Autocomplete on the same
   * page, but they've offered this workaround for cases where that is
   * unavoidable:
   * https://support.algolia.com/hc/en-us/articles/14424771026833-Can-I-have-two-AutoComplete-SearchBoxes-on-the-same-page
   *
   * Without this event listener, the autocomplete instance which was
   * initialized first will not close when a click occurs outside the dropdown
   * panel.  This fix reaches into Algolia Autocomplete's "state" API, in order
   * to manually hide both autocomplete panels when the user clicks away.
   */
  document.addEventListener("click", (event) => {
    const clickInsideAutocomplete = event.target.closest(".aa-Autocomplete")
    if (!clickInsideAutocomplete) {
      desktopAutocomplete.setIsOpen(false)
      mobileAutocomplete.setIsOpen(false)
    }
  })
})
