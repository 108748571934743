import algoliasearch from "algoliasearch/lite"
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js"
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches"
import { createAlgoliaInsightsPlugin } from "@algolia/autocomplete-plugin-algolia-insights"
import searchInsights from "search-insights"
import centsToPrice from "./utilities/centsToPrice"
import getLocaleParams from "./utilities/localeParams"

const algoliaSetup = ({
  id,
  containerId,
  buttonSelector,
  searchBoxSelector,
  closeOnScroll
}) => {
  const searchContainer = document.getElementById(containerId)

  if (!searchContainer) {
    return
  }

  const closeSearch = () => {
    searchContainer.style.display = "none"
  }

  /* Analytics tracking in Algolia can be filtered between the development,
   * staging, test, and production environments, so it's safe to track
   * analytics in non-production environments.
   * */
  const trackAnalytics =
    searchContainer.dataset.environment !== "production" ||
    (typeof Cookiebot !== "undefined" && Cookiebot.consent.marketing)

  const {
    translationRecentSearches,
    translationNoResultsMatching,
    translationCategories,
    googleTagId
  } = searchContainer.dataset

  const suggestedSearchItems = JSON.parse(
    searchContainer.dataset.suggestedSearchItems
  )

  /* When the user scrolls, we want to close the search bar (because Algolia
   * Autocomplete does _not_ play nice with sticky headers) and then unbind the
   * scroll handler.
   */
  const handleScroll = () => {
    requestAnimationFrame(closeSearch)

    document.removeEventListener("scroll", handleScroll)
  }

  const marketCountry = searchContainer.dataset.marketCountry

  for (const button of document.querySelectorAll(buttonSelector)) {
    button.addEventListener("click", (event) => {
      searchContainer.style.display = "block"

      /* If we're on desktop, select the search input.
       */
      searchContainer.querySelector(".aa-Input")?.focus()

      /* If we're on mobile, click the input-like button, to open the search
       * experience.
       */
      searchContainer.querySelector(".aa-DetachedSearchButton")?.click()

      if (closeOnScroll) {
        document.addEventListener("scroll", handleScroll)
      }

      event.stopPropagation()
    })
  }

  /* Close the drop down when the customer clicks outside the search field.
   */
  document.addEventListener("click", (e) => {
    /* This bails out if you click in either the autocomplete panel or the
     * actual site search area.
     */
    if (e.target.matches(".aa-Panel *") || e.target.matches(".site-search *")) {
      return
    }

    closeSearch()
  })

  /* This returns the path for searching for a given search query, including
   * the locale and country.
   */
  const searchPath = (query) =>
    `${searchContainer.dataset.searchPathPrefix}/search?q=${encodeURIComponent(
      query
    )}`

  const searchClient = algoliasearch(
    searchContainer.dataset.algoliaApplicationId,
    searchContainer.dataset.algoliaSearchKey
  )

  const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: "RECENT_SEARCH",
    limit: 5,
    transformSource({ source }) {
      return {
        ...source,
        getItemUrl({ item }) {
          return searchPath(item.query)
        },
        templates: {
          header({ item, html }) {
            return html`<h2>${translationRecentSearches}</h2>`
          },
          item(params) {
            const { item, html, components } = params

            return html`<a class="aa-ItemLink" href="${searchPath(item.label)}">
              ${components.Highlight({
                hit: item,
                attribute: "label",
                tagName: "b"
              })}
            </a>`
          }
        }
      }
    }
  })

  const algoliaInsightsPlugin = (appId, apiKey) => {
    searchInsights("init", {
      appId: appId,
      apiKey: apiKey,
      // This sets the _ALGOLIA cookie in the browser containing an anonymous
      // user token.
      useCookie: trackAnalytics
    })

    return createAlgoliaInsightsPlugin({
      searchInsights,
      onItemsChange({ insights, insightsEvents }) {
        const events = insightsEvents.map((insightsEvent) => ({
          ...insightsEvent,
          eventName: "Hits Viewed"
        }))
        insights.viewedObjectIDs(...events)
      },
      onSelect({ insights, insightsEvents }) {
        const events = insightsEvents.map((insightsEvent) => ({
          ...insightsEvent,
          eventName: "Hit Clicked"
        }))
        document.cookie = `_ALGOLIA_QUERY_ID=${events[0].queryID}`

        insights.clickedObjectIDsAfterSearch(...events)
      }
    })
  }

  const googleAnalyticsPlugin = (googleTagId) => {
    return {
      subscribe({ onSelect, onActive }) {
        window.dataLayer = window.dataLayer || []

        onSelect(({ item }) => {
          // Clear the previous ecommerce object.
          window.dataLayer.push({ ecommerce: null })

          window.dataLayer.push({
            event: "select_item",
            ecommerce: {
              item_list_name: "Autocomplete",
              items: [
                {
                  item_id: item.sku[0],
                  item_name: item.name,
                  item_link: item.slug,
                  item_category: item.taxon[item.taxon.length - 1]
                }
              ]
            }
          })
        })
      }
    }
  }

  const enabledPlugins = [recentSearchesPlugin]
  if (trackAnalytics) {
    enabledPlugins.push(
      algoliaInsightsPlugin(
        searchContainer.dataset.algoliaApplicationId,
        searchContainer.dataset.algoliaSearchKey
      )
    )

    if (googleTagId) {
      enabledPlugins.push(googleAnalyticsPlugin(googleTagId))
    }
  }

  return autocomplete({
    // Uncomment this line to keep the suggestion box open when it loses focus,
    // for development purposes.
    // debug: true,
    container: searchBoxSelector,
    openOnFocus: true,
    insights: trackAnalytics,
    placeholder: searchContainer.dataset.algoliaPlaceholderTranslation,
    plugins: enabledPlugins,
    onSubmit({ state }) {
      window.location.assign(searchPath(state.query))
    },
    /* We override the render function in order to conditionally render the
     * search box with a different layout and items depending on if the
     * customer has begun typing into it or not.
     */
    render({ state, elements, render, html }, root) {
      const { recentSearchesPlugin, productResults, taxonResults } = elements

      if (state.query) {
        /* Once a query has been entered, show the full search results
         * including products.
         */
        render(
          html`
            <div class="aa-PanelLayout noselect">
              <div class="aa-PanelSections aa-PanelSections--duo">
                <div class="aa-PanelSection aa-PanelSection--left">
                  <div class="aa-SimpleSection">${recentSearchesPlugin}</div>
                  <div class="aa-SimpleSection">${taxonResults}</div>
                </div>
                <div class="aa-PanelSection aa-PanelSection--right">
                  <div class="aa-ProductSection">${productResults}</div>
                </div>
              </div>
            </div>
          `,
          root
        )
      } else {
        /* We don't show the products when the customer hasn't entered any kind
         * of search query yet.
         */

        render(
          html`
            <div class="aa-PanelLayout noselect">
              <div class="aa-PanelSections">
                <div class="aa-SimpleSection">
                  <div class="aa-SourceHeader"><h2>Suggestions</h2></div>
                  <ul class="aa-List">
                    ${suggestedSearchItems.map(
                      (text) =>
                        html`
                          <li class="aa-Item">
                            <a class="aa-ItemLink" href="${searchPath(text)}">
                              <div class="aa-ProductName">${text}</div>
                            </a>
                          </li>
                        `
                    )}
                  </ul>
                </div>
                <div class="aa-SimpleSection">${recentSearchesPlugin}</div>
              </div>
            </div>
          `,
          root
        )
      }
    },
    getSources({ query }) {
      return [
        {
          sourceId: "productResults",
          getItemInputValue: ({ item }) => item.query,
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: searchContainer.dataset.algoliaProductIndex,
                  query,
                  filters: `countries:${marketCountry}`,
                  params: {
                    hitsPerPage: 6,
                    clickAnalytics: trackAnalytics
                  }
                }
              ]
            })
          },
          templates: {
            item({ item, html }) {
              const [country_iso_param, locale] = getLocaleParams()
              const url = `/${country_iso_param}/${locale}/products/${item.slug}`

              const badges = Object.entries(item.badges[marketCountry]).filter(
                ([name, text]) => text
              )

              return html`
                <a class="aa-ItemLink" href="${url}">
                  <div class="aa-BadgeList">
                    ${badges.map(
                      ([name, text]) => html`
                        <div
                          class="aa-Badge aa-Badge--${name.replace("_", "-")}"
                        >
                          <span>${text}</span>
                        </div>
                      `
                    )}
                  </div>

                  <img class="aa-ProductImage" src="${item.image}" />
                  <div class="aa-ProductName">${item.name}</div>
                  <div class="aa-ProductPrice">
                    ${item.originalPriceCents[marketCountry]
                      ? html`
                          <span class="aa-ProductPrice--Original">
                            ${centsToPrice(
                              item.originalPriceCents[marketCountry],
                              item.currencies[marketCountry]
                            )}
                          </span>
                          <span class="aa-ProductPrice--Sale">
                            ${centsToPrice(
                              item.priceCents[marketCountry],
                              item.currencies[marketCountry]
                            )}
                          </span>
                        `
                      : html`
                          ${centsToPrice(
                            item.priceCents[marketCountry],
                            item.currencies[marketCountry]
                          )}
                        `}
                  </div>
                </a>
              `
            },
            footer({ html, state }) {
              const {
                collections: [productResults]
              } = state

              if (productResults.items.length) {
                return html`
                  <div class="aa-ShopButton">
                    <a
                      class="action-button action-button--full-width"
                      href="${searchPath(state.query)}"
                    >
                      Show All Products
                    </a>
                  </div>
                `
              }
            },
            noResults({ state, html, query }) {
              const [country_iso_param, locale] = getLocaleParams()

              const fallbackItems = JSON.parse(
                searchContainer.dataset.fallbackItems
              )

              return html`
                <ul class="aa-List">
                  ${fallbackItems.map(
                    (item) => html`
                      <li class="aa-Item">
                        <a
                          class="aa-ItemLink"
                          href="/${country_iso_param}/${locale}/${item.url}"
                        >
                          <img class="aa-ProductImage" src="${item.imageUrl}" />
                          <div class="aa-ProductName">${item.name}</div>
                        </a>
                      </li>
                    `
                  )}
                </ul>
              `
            }
          }
        },
        {
          sourceId: "taxonResults",
          getItemInputValue: ({ item }) => item.query,
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: searchContainer.dataset.algoliaTaxonIndex,
                  query,
                  params: {
                    hitsPerPage: 5,
                    clickAnalytics: trackAnalytics
                  }
                }
              ]
            })
          },
          templates: {
            header({ item, html, state }) {
              let productResults = state.collections.find(
                (collection) => collection.source.sourceId == "productResults"
              )

              if (productResults.items.length != 0) {
                return html`<h2>${translationCategories}</h2> `
              }
            },
            item(params) {
              const { item, html, components } = params

              return html`<a
                class="aa-ItemLink"
                href="${searchContainer.dataset
                  .searchPathPrefix}/${item.permalink}"
              >
                ${components.Highlight({
                  hit: item,
                  attribute: "name",
                  tagName: "b"
                })}
              </a>`
            },
            noResults({ state, html, query }) {
              let productResults = state.collections.find(
                (collection) => collection.source.sourceId == "productResults"
              )

              if (productResults.items.length === 0) {
                return html`
                  <div class="aa-NoResultsFound__text">
                    ${translationNoResultsMatching}
                    <h2>'${state.query}'</h2>
                  </div>
                `
              }
            }
          }
        }
      ]
    }
  })
}

export default algoliaSetup
