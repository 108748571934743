const bindMiniCartEvents = (miniCart, hideDelay, close) => {
  miniCart
    .querySelector(".mini-cart-added__close-button")
    .addEventListener("click", close)

  // This prevents click events from inside the component from escaping, so
  // that they can't trigger the event handler on the document that closes the
  // dropdown.
  miniCart.addEventListener("click", (event) => event.stopPropagation())

  document.addEventListener("click", () => {
    close()
  })

  if (hideDelay) {
    let timeout = setTimeout(close, hideDelay)

    miniCart.addEventListener("mouseenter", () => {
      clearTimeout(timeout)
    })

    miniCart.addEventListener("mouseleave", () => {
      timeout = setTimeout(close, hideDelay)
    })
  }
}

window.miniCartAdded = (miniCartAddedFragment, miniCartFragment, cartCount) => {
  const cartCountElement = document.getElementById("cart-count")
  const mobileCartCountElement = document.querySelector(
    ".mobile-header__cart-count"
  )

  if (cartCount) {
    cartCountElement.innerHTML = cartCount
    mobileCartCountElement.innerHTML = cartCount
  }

  const miniCartAddedHook = document.getElementById("mini-cart-added")

  if (miniCartAddedHook) {
    miniCartAddedHook.innerHTML = miniCartAddedFragment

    const miniCartAdded = miniCartAddedHook.children[0]

    if (miniCartAdded) {
      bindMiniCartEvents(miniCartAdded, 3000, () => miniCartAdded.remove())
    }
  }

  const miniCartHook = document.getElementById("mini-cart")

  if (miniCartHook) {
    miniCartHook.innerHTML = miniCartFragment

    const miniCart = miniCartHook.children[0]

    if (miniCart) {
      bindMiniCartEvents(miniCart, false, () =>
        miniCart.classList.remove("mini-cart-added--open")
      )
    }
  }
}

const enableMiniCart = () => {
  // The element with the ID mini-cart is just a wrapper where we put the
  // actual mini cart component. Because they currently share all the styles
  // and I don't have time to do this refactoring, the class of the component
  // itself is mini-cart-added.
  const miniCart = document.querySelector("#mini-cart .mini-cart-added")

  if (!miniCart) {
    return
  }

  bindMiniCartEvents(miniCart, false, () =>
    miniCart.classList.remove("mini-cart-added--open")
  )
}

const enableMiniCartButton = () => {
  const miniCartButton = document.getElementById("cart-link")

  if (!miniCartButton) {
    return
  }

  miniCartButton.addEventListener("click", (e) => {
    const miniCart = document.querySelector("#mini-cart .mini-cart-added")

    if (!miniCart) {
      return
    }

    const orderNumber = miniCart.dataset["orderNumber"]
    const miniCartItemsPlaceholder = miniCart.querySelector(
      ".mini-cart-added__items--placeholder"
    )

    miniCart.classList.toggle("mini-cart-added--open")

    e.preventDefault()
    e.stopPropagation()

    // This code will replace the miniCartItemsPlaceholder DOM element with
    // rendered HTML from the JSON. The conditional here prevents the user
    // from sending repeated fetch requests when they open and close the
    // mini cart.
    if (miniCartItemsPlaceholder) {
      const miniCartItems = miniCartItemsPlaceholder.parentElement
      miniCartItemsPlaceholder.remove()

      fetch(`/orders/${orderNumber}/mini_cart_data`)
        .then((response) => response.json())
        .then((json) => (miniCartItems.innerHTML = json["miniCart"]))
    }
  })
}

// This code manages showing and hiding the drawers of the main site navigation
// as the user mouses over each tab.
window.addEventListener("DOMContentLoaded", () => {
  enableMiniCart()
  enableMiniCartButton()
})
